<template>
  <div v-if="!showFooter" class="footer_container">
    <div class="content">
      <div class="footer-left">
        <p class="title">湖北松石检测技术有限公司</p>
        <p class="title">湖北省绿松石产品质量检验检测中心</p>
        <p>总部地址：湖北省十堰市竹山县城关镇莲花村国际绿松石城21号楼1单元3楼
        </p>
        <p>业务电话：18726611368</p>
        <p>业务邮箱：493850893@qq.com
        </p>
      </div>
      <div class="site">
        <div class="nav">
          <ul class="starlist">
                <li :class="path == '/' ? 'active' : ''">
                    <span>
                        <router-link :to="'/'" class="hand-style">
                            首页
                        </router-link>
                    </span>
                </li>
                <!-- <li :class="path == '/tags' ? 'active' : ''">
                    <span>
                        <router-link :to="'/tags'" class="hand-style">
                          行业资讯
                        </router-link>
                    </span>
                </li> -->
                <li :class="path == '/tags'
                    ? 'active' : ''">
                    <el-dropdown trigger="hover">
                        <span class="el-dropdown-link hand-style">
                            行业资讯<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <router-link v-for="(item, index) in categoryList" class="sub-link" style="text-decoration: none;color: var(--theme-color);" :to="`/tags?categoryId=${item.id}`">
                                <el-dropdown-item>
                                   {{ item.name }}
                                </el-dropdown-item>
                            </router-link>
                        </el-dropdown-menu>
                    </el-dropdown>
                </li>
                <!-- <li :class="path == '/rangeDetail' ? 'active' : ''">
                    <span>
                        <router-link :to="'/rangeDetail'" class="hand-style">
                          检测范围
                        </router-link>
                    </span>
                </li> -->
                <li :class="path == '/rangeDetail' ? 'active' : ''">
                    <span>
                        <router-link :to="'/rangeDetail'" class="hand-style">
                          检测范围
                        </router-link>
                    </span>
                </li>
                <li :class="path == '/links' ? 'active' : ''">
                    <span>
                        <router-link :to="'/links'" class="hand-style">
                             联系我们
                        </router-link>
                    </span>
                </li>
                <li :class="path == '/about' ? 'active' : ''">
                    <span>
                        <router-link :to="'/about'" class="hand-style">
                            关于我们
                        </router-link>
                    </span>
                </li>
            </ul>
        </div>
        <div class="web-notice">
          <span>
            CopyRight@2024 湖北松石检测技术有限公司 ｜ 鄂ICP备2024071149号-1
          </span>
          <span class="notice-text">
            <router-link :to="'/message'" class="hand-style">
                公正性声明
            </router-link>
          </span>
          <!-- <p>
            Copyright©2022-{{ nowYear }} <a href="https://gitee.com/quequnlong/turquoise-blog" target="_blank">{{$store.state.webSiteInfo.name}}</a>
            <a href="https://beian.miit.gov.cn/" target="_blank" style="margin-left: 10px">{{
                $store.state.webSiteInfo.recordNum
              }}</a>
          </p> -->
          <div>
            <div>
                <el-dropdown trigger="hover">
                  <span class="el-dropdown-link xiangguan-link">
                      友情链接<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item>
                          <a href="http://zbxy.cug.edu.cn/" target="_blank">中国地质大学</a>
                      </el-dropdown-item>
                      <el-dropdown-item>
                          <a href="http://www.gic.cug.edu.cn/" target="_blank">地大珠宝检测中心</a>
                      </el-dropdown-item>
                      <el-dropdown-item>
                          <a href="http://www.zhushan.gov.cn/" target="_blank">竹山县人民政府网</a>
                      </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
            </div>
            <!-- <div class="searchBox">
                <div class="search_ico">
                    <div class="hand-style" @click="handleOpenSearchDialog">
                        搜索新闻...
                        <span>
                            Ctrl+K
                        </span>
                        <i class="iconfont icon-search"></i>
                    </div>
                </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { featchCategory } from '@/api'
export default {
  data() {
    return {
      day: "",
      hours: "",
      minute: "",
      second: "",
      categoryList: [],
      nowYear: new Date().getFullYear(),
      showFooter: false
    }
  },
  watch: {
    $route(newVal, old) {
      if (newVal.fullPath === '/message' || newVal.fullPath === '/chat' || newVal.fullPath === '/newposts') {
        this.showFooter = true
      } else {
        this.showFooter = false
      }
    }
  },
  created() {
    this.timer = setInterval(this.runTime, 1000);
  },
  mounted() {
    this.fetchCategoryList()
  },
  methods: {
    fetchCategoryList() {
        featchCategory().then(res => {
            this.categoryList.push(...res.data)
            console.log('-1111122223333---categoryList', this.categoryList)
        })
    },
    runTime() {
      var timeold =
          new Date().getTime() -
          new Date(1627747200000).getTime();
      var msPerDay = 24 * 60 * 60 * 1000;
      var daysold = Math.floor(timeold / msPerDay);
      var day = new Date();
      this.day = daysold;
      this.hours = day.getHours();
      this.minute = day.getMinutes();
      this.second = day.getSeconds();
    },
  }
}
</script>
<style lang="scss" scoped>
  /deep/ .el-dropdown-menu__item:not(.is-disabled):hover {
      color: #c09836;
  }
@keyframes yb {
  10% {
    transform: rotate(15deg);
  }

  20% {
    transform: rotate(-10deg);
  }

  30% {
    transform: rotate(5deg);
  }

  40% {
    transform: rotate(-5deg);
  }

  50%,
  100% {
    transform: rotate(0deg);
  }
}


.footer_container {
  padding: 40px 80px;
  position: relative;
  bottom: 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: url(../../assets/img/footBg.jpg) no-repeat center;
  background-position: 100% 0;
  .searchBox {
      right: 50px;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;

      .search_ico {
          position: absolute;
          right: 5px;
          top: 0;
          padding: 0;
          margin: 0;
          line-height: 60px;


          div {
              width: 180px;
              border: 1px solid var(--border-line);
              height: 35px;
              line-height: 35px;
              margin-top: 13px;
              font-size: 15px;
              border-radius: 5px;
              color: #82848a;

              .iconfont {
                  font-weight: 700;
                  margin: 0 5px;
              }

              span {
                  border: 1px solid var(--border-line);
                  border-radius: 3px;
                  padding: 2px 2px;
                  margin-left: 15px;
                  background-color: var(--background-color);
              }
          }
      }
  }

  .xiangguan-link {
    font-size: 16px;
    color: white;
  }

  .content {
    // margin: 0 auto;
    // position: relative;
    // text-align: center;
    display: flex;
    width: 100%;
    .footer-left {
      width: 40%;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.7);
      line-height: 32px;
      .title {
        font-size: 20px;
        color: #ffffff;
        margin-bottom: 12px;
      }
    }

    .site {
      font-size: 16px;
      color: white;
      padding-left: 80px;
      position: relative;
      width: 60%;
      .nav {
        li {
          color: white !important;
          float: left;
          display: block;
          font-size: 18px;
          padding: 0 20px;
          position: relative;
        }
      }

      p {
        margin: 10px 0;
      }

      a {
        text-decoration: none;
        color: var(--theme-color);

        &:hover {
          color: var(--theme-color);
        }
      }
      .web-notice {
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        right: 20px;
        bottom: 0;
        .notice-text {
          padding-left: 40px;
          color: rgba(255, 255, 255, 0.7);
          font-size: 16px;
          padding-right: 40px;

          .hand-style {
            font-size: 16px;
            transition: 0.3s;
          }
        }
      }
      .hand-style {
        color: #fff;
        font-size: 20px;
        transition: 0.3s;
      }
      .run_time {
        height: 20px;
        align-items: center;

        svg {
          width: 19px;
          height: 19px;
          vertical-align: -4px;
          animation: yb 1s linear infinite;
        }

        span {
          line-height: 20px;
          font-size: 16px;
          padding-left: 5px;

          strong {
            color: var(--theme-color);
          }
        }
      }
    }

    .site-number {

      i {
        display: inline-block;
        font-size: 12px;
      }

      .site_pv,
      .site_uv,
      .online_user {
        position: relative;
        display: inline-block;
        margin-right: 4px;
        height: 24px;
        padding-left: 10px;
        line-height: 24px;
        overflow: hidden;
        font-size: 12px;
        font-weight: 700;
        color: var(--background-color);
        background: var(--text-color1);
        border-radius: 5px;
      }

      .pv_num,
      .uv_num,
      .online_num {
        display: inline-block;
        font-size: 12px;
        padding: 0 15px;
      }

      .pv_num {
        background: linear-gradient(0deg, #d0339b, #d03336);
      }

      .uv_num {
        background: linear-gradient(0deg, #e2c63a, #f58044);
      }

      .online_num {
        background: linear-gradient(0deg, #367eda, #964fdb);

      }


    }

  }
}

@media screen and (max-width: 1118px) {
  .site {
    display: none;
  }
  .footer-left {
    width: 100% !important;
  }
}
</style>
