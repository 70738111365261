<template>
  <header :class="headerClass">
    <div id="pre" :style="{ width: widthPre + '%' }"></div>
    <nav>
      <!-- <div class="logo">
                <div class="logoBox">
                    <router-link :to="'/'" class="hand-style">
                        <el-image class="img"
                            style="height: 50px;"
                            :src="$store.state.webSiteInfo.logo">
                        </el-image>
                        <span class="name">{{$store.state.webSiteInfo.name}}</span>
                    </router-link>

                </div>
                <span v-if="isMobile" style="position: absolute;left: 10px;">
                    <a @click="openDrawer" style="font-size:20px">
                        <i class="iconfont icon-menu_normal" style="font-size: 1.5rem;" />
                    </a>
                </span>
                <span v-if="isMobile" style="position: absolute;right: 10px;">
                    <a @click="handleOpenSearchDialog" style="font-size:20px">
                        <i class="iconfont icon-search" style="font-size: 1.5rem;" />
                    </a>
                </span>
            </div>

            <ul class="starlist">
                <li :class="path == '/' ? 'active' : ''">
                    <span>
                        <router-link :to="'/'" class="hand-style">
                            首页
                        </router-link>
                    </span>
                </li>
                <li :class="path == '/tags'
                    ? 'active' : ''">
                    <el-dropdown trigger="hover">
                        <span class="el-dropdown-link hand-style">
                            行业资讯<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <router-link v-for="(item, index) in categoryList" class="sub-link" style="text-decoration: none;color: var(--theme-color);" :to="`/tags?categoryId=${item.id}`">
                                <el-dropdown-item>
                                   {{ item.name }}
                                </el-dropdown-item>
                            </router-link>
                        </el-dropdown-menu>
                    </el-dropdown>
                </li>
                <li :class="path == '/rangeDetail' ? 'active' : ''">
                    <span>
                        <router-link :to="'/rangeDetail'" class="hand-style">
                            检测范围
                        </router-link>
                    </span>
                </li>
                <li :class="path == '/links' ? 'active' : ''">
                    <span>
                        <router-link :to="'/links'" class="hand-style">
                             联系我们
                        </router-link>
                    </span>
                </li>
                <li :class="path == '/about' ? 'active' : ''">
                    <span>
                        <router-link :to="'/about'" class="hand-style">
                            关于我们
                        </router-link>
                    </span>
                </li>
                <li :class="path == '/certificate' ? 'active' : ''">
                    <span>
                        <router-link :to="'/certificate'" class="hand-style">
                            证书查询
                        </router-link>
                    </span>
                </li> 

            </ul> -->

      <!-- <div class="searchBox">
                <div class="search_ico">
                    <div class="hand-style" @click="handleOpenSearchDialog">
                        <i class="iconfont icon-search"></i>
                        搜索新闻...
                        <span>
                            Ctrl+K
                        </span>
                    </div>
                </div>
            </div> -->
      <div class="header-top">
        <div class="title" @click="handlToHome">
          <!-- <div>湖北松石检测技术有限公司</div>
          <div class="sub-title">湖北省绿松石产品质量检验检测中心</div> -->
          <img src="../../assets/img/logoName.jpg" />
        </div>
        <div class="right-header-block">
          <ul class="starlist">
            <li :class="path == '/' ? 'active' : ''">
              <span>
                <router-link :to="'/'" class="hand-style"> 首页 </router-link>
              </span>
            </li>
            <li :class="path == '/tags' ? 'active' : ''">
              <el-dropdown trigger="hover">
                <span class="el-dropdown-link hand-style">
                  行业资讯<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <router-link
                    v-for="(item, index) in categoryList"
                    class="sub-link"
                    style="text-decoration: none; color: #c09836"
                    :to="`/tags?categoryId=${item.id}`"
                  >
                    <el-dropdown-item>
                      {{ item.name }}
                    </el-dropdown-item>
                  </router-link>
                </el-dropdown-menu>
              </el-dropdown>
            </li>
            <li :class="path == '/rangeDetail' ? 'active' : ''">
              <span>
                <router-link :to="'/rangeDetail'" class="hand-style">
                  检测范围
                </router-link>
              </span>
            </li>
            <li :class="path == '/links' ? 'active' : ''">
              <span>
                <router-link :to="'/links'" class="hand-style">
                  联系我们
                </router-link>
              </span>
            </li>
            <li :class="path == '/about' ? 'active' : ''">
              <span>
                <router-link :to="'/about'" class="hand-style">
                  关于我们
                </router-link>
              </span>
            </li>
            <!-- <li :class="path == '/certificate' ? 'active' : ''">
                    <span>
                        <router-link :to="'/certificate'" class="hand-style">
                            证书查询
                        </router-link>
                    </span>
                </li>  -->
          </ul>
          <div class="searchBox">
            <div class="search_ico">
              <div class="hand-style-input" @click="handleOpenSearchDialog">
                搜索新闻...
                <span> Ctrl+K </span>
                <i class="iconfont icon-search"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>
<script>
import { logout, featchCategory } from "@/api";
import { removeToken, getToken } from "@/utils/cookieUtil";
export default {
  name: "Header",

  props: {
    userInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      widthPre: "",
      keywords: null,
      user: this.$store.state.userInfo,
      style: null,
      categoryList: [],
      rangeList: [
        { name: "珠宝玉石", id: 1 },
        { name: "绿松石", id: 2 },
        { name: "贵金属饰品", id: 3 },
      ],
      path: null,
      isMobile: false,
      showSearch: false,
      showUser: true,
      windowWidth: 0,
      headerClass: "header",
      adminUrl: process.env.VUE_APP_ADMIN_API,
      noticeList: ["系统通知", "评论", "私信"],
    };
  },
  mounted() {
    this.isMobile = window.innerWidth < 1119;
    this.fetchCategoryList();
  },

  created() {
    window.addEventListener(
      "resize",
      () => {
        this.windowWidth = window.innerWidth; // 宽
      },
      true
    );
    window.addEventListener("scroll", this.scrollFn, false);
  },

  watch: {
    // 监听页面宽度
    windowWidth(val) {
      this.isMobile = val < 1119;
      this.showSearch = val < 1500;
      this.showUser = val > 1350;
    },
    $route(newVal, old) {
      this.path = newVal.fullPath;
    },
  },

  methods: {
    handlToHome() {
      this.$router.push({ path: "/" });
    },
    fetchCategoryList() {
      featchCategory().then((res) => {
        this.categoryList.push(...res.data);
        console.log("----categoryList", this.categoryList);
      });
    },
    handleOpenSearchDialog() {
      this.$store.state.searchDialogVisible = true;
    },
    handleClickNotice(index) {
      if (!getToken()) {
        this.$store.commit("setLoginFlag", true);
        return;
      }
      if (index == this.noticeList.length - 1) {
        this.$router.push({ path: "/im" });
        return;
      }
      this.$router.push({ path: "/notice", query: { type: index } });
    },
    topBageShow() {
      return (
        this.$store.state.systemNotcie.system > 0 ||
        this.$store.state.systemNotcie.comment > 0 ||
        this.$store.state.systemNotcie.private > 0
      );
    },
    validata(index) {
      switch (index) {
        case 0:
          return this.$store.state.systemNotcie.system > 0;
        case 1:
          return this.$store.state.systemNotcie.comment > 0;
        case 2:
          return this.$store.state.systemNotcie.private > 0;
        default:
          return false;
      }
    },

    scrollFn() {
      // 页面滚动距顶部距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let scroll = scrollTop - this.i;
      this.i = scrollTop;

      // 鼠标向上滚动
      if (scroll < 0) {
        this.headerClass = "header slideDown";
      } else {
        this.headerClass = "header slideUp";
      }
      let page = document.body.scrollHeight;
      let client = document.documentElement.clientHeight;
      let comend = page - client;
      let scrTop = document.documentElement.scrollTop;
      this.widthPre = Math.round((scrTop / comend) * 10000) / 100;
    },
    handleLogin() {
      this.$store.commit("setLoginFlag", true); // 存储到vuex
    },
    logout() {
      //如果在个人中心则跳回上一页
      if (this.path === "/user") {
        this.$router.go(-1);
      }
      logout()
        .then((res) => {
          removeToken();
          this.$store.commit("setUserInfo", null);
          location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openDrawer() {
      this.$store.commit("setDrawer", true);
    },
  },
};
</script>
<style scoped lang="scss">
/deep/ .el-dropdown-menu__item:not(.is-disabled):hover {
  color: #c09836;
}
.header-top {
  display: flex;
  // padding: 0 40px;
  height: 80px;
  justify-content: space-between;
  align-items: center;
  .title {
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    height: 100%;
    padding-left: 10px;
    img {
      height: calc(100% - 20px);
      margin-top: 10px;
    }
    .sub-title {
      margin-top: 8px;
    }
  }
}
.right-header-block {
  display: flex;
  align-items: center;
}
.hand-style-input {
  width: 200px;
  height: 40px;
  border-radius: 24px;
  // border: 1px solid #8a8a8b;
  padding-left: 20px;
  color: #8a8a8b;
}
.notice-bage {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
  display: inline-block;
}

.topBage {
  vertical-align: 15px;
}

.sub-link {
  color: red !important;
}

#pre {
  position: fixed;
  top: 0;
  height: 2px;
  background-image: -webkit-linear-gradient(0deg, #3ca5f6 0, #a86af9 100%);
}

@media screen and (max-width: 1118px) {
  .header {
    position: fixed;
    top: 0;
    width: 100%;
    line-height: 40px;
    z-index: 99;
    background-color: white;
    a {
      text-decoration: none;
    }

    nav {
      width: 100%;
      margin: auto;
      overflow: hidden;
      max-width: 1500px;

      .logo {
        font-size: 22px;
        font-weight: 700;
        width: 100%;
        position: relative;
        display: flex;
        color: var(--text-color);

        .logoBox {
          height: 60px;
          display: flex;
          align-items: center;
          margin-left: 20px;
          position: relative;
          margin: 0 auto;
        }

        .img {
          width: 80px;
          height: 40px;
          vertical-align: middle;
          // margin-top: -6px;
        }

        .name {
          margin-left: 10px !important;
          // color: var(--theme-color) !important;
          font-family: "Lucida Calligraphy", cursive, serif, sans-serif;
        }
      }

      .starlist {
        display: none;
      }
      .articleBtn,
      .starlist,
      .noticeBtn,
      .userInfo {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 1119px) {
  /*针对宽度小于等于767px的屏幕设备，定义如下样式*/
  .slideDown {
    transition: transform 0.5s ease-out;
    transform: translateY(0);
  }

  .slideUp {
    transition: transform 0.5s ease-out;
    transform: translateY(-100px);
  }

  .header {
    position: fixed;
    top: 0;
    width: 100%;
    line-height: 40px;
    z-index: 99;
    background-color: var(--header-back-color);
    backdrop-filter: blur(25px);
    box-shadow: 0 1px 40px -8px rgba(0, 0, 0, 0.5);

    a {
      text-decoration: none;
    }

    nav {
      width: 100%;
      margin: auto;
      overflow: hidden;
      max-width: 1500px;

      .logo {
        float: left;
        font-size: 22px;
        font-weight: 700;

        .img {
          vertical-align: middle;
          margin-top: -6px;
        }

        .name {
          margin-left: 10px !important;
          color: var(--theme-color) !important;
        }
      }

      .starlist {
        display: block;
        float: left;
        margin-left: 30px;
        overflow: hidden;
        max-width: 1500px;

        li {
          float: left;
          display: block;
          font-size: 18px;
          padding: 0 20px;
          position: relative;

          // &:hover::before {
          //     content: "";
          //     position: absolute;
          //     top: 0;
          //     left: 0;
          //     right: 0;
          //     bottom: 0;
          //     width: 60%;
          //     height: 4px;
          //     border-bottom-left-radius: 15px;
          //     border-bottom-right-radius: 15px;
          //     background-color: var(--theme-color);
          //     margin: 0 auto;
          //     text-align: center;
          //     animation: fade-in 0.3s linear 1;
          // }

          @keyframes fade-in {
            0% {
              transform: scale(0);
            }

            100% {
              transform: scale(1);
            }
          }

          svg {
            width: 18px;
            height: 18px;
            vertical-align: -3px;
          }

          i {
            font-weight: 700;
          }

          a {
            color: #71777c;

            &:hover {
              color: var(--theme-color);
            }
          }
        }

        .active {
          // &::before {
          //     content: "";
          //     position: absolute;
          //     top: 0;
          //     left: 0;
          //     right: 0;
          //     bottom: 0;
          //     width: 60%;
          //     height: 4px;
          //     border-bottom-left-radius: 15px;
          //     border-bottom-right-radius: 15px;
          //     background-color: var(--theme-color);
          //     margin: 0 auto;
          //     text-align: center;
          // }

          /deep/ a,
          span {
            color: var(--theme-color) !important;
            font-weight: 700;
            color: #c09836;
          }
        }
      }

      .searchBox {
        // position: absolute;
        // right: 50px;
        // top: 0;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;

        .search_ico {
          // position: absolute;
          right: 5px;
          // top: 0;
          padding: 0;
          margin: 0;
          line-height: 60px;

          div {
            width: 180px;
            border: 1px solid var(--border-line);
            height: 35px;
            line-height: 35px;
            margin-left: 20px;
            font-size: 15px;
            border-radius: 5px;
            color: #82848a;

            .iconfont {
              font-weight: 700;
              margin: 0 5px;
              margin-left: 10px;
            }

            span {
              border: 1px solid var(--border-line);
              border-radius: 3px;
              padding: 2px 2px;
              margin-left: 15px;
              background-color: var(--background-color);
            }
          }
        }
      }

      .noticeBtn {
        position: absolute;
        right: 250px;
        top: 0;

        svg {
          width: 20px;
          height: 20px;
        }

        i {
          font-size: 1.5rem;
          color: var(--article-color);
        }

        /deep/ .el-dropdown {
          width: 35px;
          height: 35px;
          right: -10px;
          top: 5px;
        }
      }

      .el-dropdown-link {
        font-size: 18px !important;
      }

      /deep/ .el-dropdown-link:hover {
        font-size: 18px !important;
        color: #c09836 !important;
      }

      .userInfo {
        position: absolute;
        right: 190px;
        top: 0;

        /deep/ .el-dropdown {
          width: 35px;
          height: 35px;
          right: 0;
          top: 10px;
        }

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 1px solid var(--border-line);
          position: absolute;
        }
      }
    }
  }
}
</style>
